import React, { useContext, useEffect, useState } from "react";
import BalanceWrapper from "../components/BalanceWrapper";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MenuNotification from "../components/MenuNotification";
import Tabs from "../components/Tabs";
import TopSlider from "../components/TopSlider";
import {
  getCategoryDetail,
  getNotificationCount,
  getPromotions,
  getWallet,
  productCategories,
} from "../services";
import { AppContext } from "../App";
import { toast } from "react-toastify";
import MenuSidebar from "../components/MenuSidebar";
import AuthService from "../service/auth.service";
import UserService from "../service/user.service";

function Home() {
  const [state, setState] = useContext(AppContext);
  const [tabNum, setTabNum] = useState("");
  const [images, setImages] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [newsfeeds, setNewsfeeds] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [gamelist, setGamelist] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [gameData, setGameData] = useState();
  const [selectedGame, setSelectedGame] = useState("");
  const [isImgLoading, setImgLoading] = useState(false);
  const [text, setText] = useState("");
  const [androidGameUrl, setAndroidGameUrl] = useState("");
  const [ios32GameUrl, setios32GameUrl] = useState("");
  const [ios64GameUrl, setios64GameUrl] = useState("");
  const [walletInfo, setWalletInfo] = useState(null);

  useEffect(() => {
    setState({
      ...state,
      categoryLoading: true,
    });
    if (window.refreshSlider) {
      window.refreshSlider();
    }
  }, []);

  const getSliderImgs = async () => {
    setLoading(true);
    try {
      const games = await UserService.getSliderImages();
      let imgArr = [];
      if (games.data) {
        games.data.map((game) => {
          if (game.image) {
            imgArr.push({ imgSrc: game.image });
          }
        });
        setImages(imgArr);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getTabslist = async () => {
    setLoading(true);
    try {
      const tabslist = await UserService.getTablist();
      if (tabslist.data) {
        setTabs(tabslist.data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getNewsfeedsList = async () => {
    setLoading(true);
    try {
      const newslist = await UserService.getNewsfeeds();
      console.log(newslist);
      setNewsfeeds(newslist.data || []);
    } catch (err) {
      console.log("setNewsfeedserror");
      console.log(err);
    }
    setLoading(false);
  };

  const getWalletInfo = async () => {
    setLoading(true);
    try {
      const getWallet = await UserService.getBalance();
      setWalletInfo(getWallet.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getProductList = async () => {
    setLoading(true);
    try {
      if (tabNum === "news") return;
      const productlist = await UserService.getProducts(tabNum);
      setGamelist(productlist.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleProduct = async (ltype, id) => {
    if (selectedGame) {
      await stopProduct(selectedGame);
    }

    setLoading(true);

    try {
      if (ltype === "h5") {
        const win = window.open("about:blank");
        const res = await UserService.startProduct(id);
        setSelectedGame(id);
        await getWalletInfo();
        win.location = res.data.url;
      }

      if (ltype === "app") {
        const res = await UserService.startProduct(id);
        setSelectedGame(id);
        setGameData(res.data);
        if (res.data.name === "Pussy888") {
          setAndroidGameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "918kiss") {
          setAndroidGameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Mega888") {
          setAndroidGameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Evo888") {
          setAndroidGameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
        }
        setOpen(true);
        await getWalletInfo();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.detail) {
          toast.error(`${data.detail}`, { theme: "dark" });
          //   Swal.fire({
          //     icon: "error",
          //     text: `${err?.response?.data?.detail}`,
          //   });
          window.close();
        }
      }
    }
    setLoading(false);
  };

  const stopProduct = async (id) => {
    setLoading(true);
    try {
      await UserService.stopProduct(id);
      await getWalletInfo();
      setSelectedGame("");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProductList();
  }, [tabNum]);

  useEffect(() => {
    getSliderImgs();
    getTabslist();
    getNewsfeedsList();
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, [localStorage.getItem("user")]);

  return (
    <div className="t1">
      <div className="page">
        <div class="page-content footer-clear">
          <Header currentUser={currentUser} />
          <div style={{ padding: "0 12px" }}>
            <TopSlider images={images} />
            <BalanceWrapper />
            <Tabs
              tabNum={tabNum}
              setTabNum={setTabNum}
              gamelist={gamelist}
              selectedGame={selectedGame}
              handleProduct={handleProduct}
              stopProduct={stopProduct}
            />
          </div>
        </div>
        <Footer />
        <MenuNotification />
        <MenuSidebar />
      </div>
    </div>
  );
}

export default Home;
