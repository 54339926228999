import React from "react";
import { useNavigate } from "react-router-dom";

const BalanceWrapper = () => {
  const navigate = useNavigate();
  return (
    <div className="balance_wrapper">
      <div className="pbalance">
        <div className="balance_btns">
          <a
            href=".#"
            className="btns"
            onClick={(e) => {
              e.preventDefault();
              navigate("/deposit");
            }}
          >
            <img
              src={require("../../src/images/deposit.png")}
              alt=""
              width="25px"
              height="25px"
            />
            Deposit
          </a>
          <a
            href=".#"
            className="btns withdraw_btn"
            onClick={(e) => {
              e.preventDefault();
              navigate("/withdraw");
            }}
          >
            <img
              src={require("../../src/images/widthrow.png")}
              alt=""
              width="25px"
              height="25px"
            />
            Withdraw
          </a>
        </div>
      </div>
      <div className="jackpot">
        <img src={require("../images/jackpot.png")} alt="" />
      </div>
    </div>
  );
};

export default BalanceWrapper;
