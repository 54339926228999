import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MenuSidebar from "./MenuSidebar";
import { toast } from "react-toastify";
import authService from "../service/auth.service";
import userService from "../service/user.service";

const Settings = () => {
  const navigate = useNavigate();

  const [modalOpen, setModal] = useState(false);
  const [userId, setUserId] = useState("");

  const handleFormFailed = () => {
    toast.error("No inputs should be empty!", { theme: "dark" });
  };

  const getUserInfo = async () => {
    try {
      const userInfo = await userService.getBalance();
      setUserId(userInfo.data.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleFormFinish = async (values) => {
    console.log(values);
    setModal(false);
    try {
      console.log(values, userId);
      await authService.changePassword({ values, userId });
      toast.success("Password changed successfully!", { theme: "dark" });
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.old_password) {
          const msg = data.old_password.old_password;
          return toast.error(msg, { theme: "dark" });
        } else if (data.password) {
          const msg = data.password[0];
          return toast.error(msg, { theme: "dark" });
        } else if (data.password2) {
          const msg = data.password2[0];
          return toast.error(msg, { theme: "dark" });
        } else if (data.detail) {
          const msg = data.detail[0];
          return toast.error(msg, { theme: "dark" });
        } else {
          return toast.error("Failed to change the password!", {
            theme: "dark",
          });
        }
      }
    }
  };

  return (
    <div className="t1">
      <Header />
      <div id="settings">
        <p className="setting_head mb-1">SETTING</p>
        <p
          onClick={() => setModal(true)}
          className="change_pswd w-100"
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "white",
            borderBottomStyle: "solid",
            marginBottom: "8px",
            paddingBottom: "1rem",
            paddingTop: "1rem",
          }}
        >
          Change Password
        </p>
        <a
          href=".#"
          className="logout w100"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Logout
        </a>
      </div>
      <Footer />
      <MenuSidebar />
      <Modal
        title="Change Password"
        open={modalOpen}
        footer={[]}
        onCancel={() => setModal(false)}
      >
        <div className="modal-box">
          <Form onFinish={handleFormFinish} onFinishFailed={handleFormFailed}>
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px", marginTop: "1.5rem" }}
                placeholder="Current password"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px" }}
                placeholder="New password"
              />
            </Form.Item>
            <Form.Item
              name="password2"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
            >
              <Input.Password
                style={{
                  marginBottom: "8px",
                  padding: "12px",
                }}
                placeholder="Confirm new password"
              />
            </Form.Item>
            <div className="balance_btns" style={{ width: "100%" }}>
              <button
                type="submit"
                className="btns"
                style={{
                  width: "100%",
                  flex: 1,
                  border: "none",
                  padding: "10px 0",
                }}
              >
                Change Password
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
