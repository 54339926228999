import React from 'react'

const Contact = () => {
  return (
    <div className="custom_contact">
    <p>Please contact us :</p>
    <div className='row form-row'>
        <div className="col-6 col-sm-4">
                <div className="box-contact">
                    <a href="">
                        <img src={require("../images/bg-contact.png")} alt="" width="100%" />
                    </a>
                    <div className="info-contact">
                        <div><img src={require("../images/contact/line.png")} alt="" width="30px" /></div>
                        <div className="text-contact">XXXX-XX</div>
                    </div>
            </div>
        </div>
        <div className="col-6 col-sm-4">
            <div className="box-contact">
                <a href="">
                    <img src={require("../images/bg-contact.png")} alt="" width="100%" />
                </a>
                <div className="info-contact">
                    <div><img src={require("../images/contact/live-chat.png")} alt="" width="30px" /></div>
                    <div className="text-contact">XXXX-XX</div>
                </div>
            </div>
        </div>
        <div className="col-6 col-sm-4">
            <div className="box-contact">
                <a href="">
                    <img src={require("../images/bg-contact.png")} alt="" width="100%" />
                </a>
                <div className="info-contact">
                    <div><img src={require("../images/contact/skype.png")} alt="" width="30px" /></div>
                    <div className="text-contact">XXXX-XX</div>
                </div>
            </div>
        </div>
        <div className="col-6 col-sm-4">
            <div className="box-contact">
                <a href="">
                    <img src={require("../images/bg-contact.png")} alt="" width="100%" />
                </a>
                <div className="info-contact">
                    <div><img src={require("../images/contact/waht-app.png")} alt="" width="30px" /></div>
                    <div className="text-contact">XXXX-XX</div>
                </div>
            </div>
        </div>
        <div className="col-6 col-sm-4">
            <div className="box-contact">
                <a href="">
                    <img src={require("../images/bg-contact.png")} alt="" width="100%" />
                </a>
                <div className="info-contact">
                    <div><img src={require("../images/contact/phone.png")} alt="" width="30px" /></div>
                    <div className="text-contact">XXXX-XX</div>
                </div>
            </div>
        </div>
        <div className="col-6 col-sm-4">
            <div className="box-contact">
                <a href="">
                    <img src={require("../images/bg-contact.png")} alt="" width="100%" />
                </a>
                <div className="info-contact">
                    <div><img src={require("../images/contact/email.png")} alt="" width="30px" /></div>
                    <div className="text-contact">XXXX-XX</div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Contact