import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const TopSlider = ({ images }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="">
      <div className="slider-wrapper" style={{ height: "150px" }}>
        {images.length > 0 && (
          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            showDots={false}
            ssr={true}
            shouldResetAutoplay={false}
            autoPlaySpeed={5000}
            autoPlay={true}
          >
            {images.map((item) => (
              <img
                key={item.imgSrc}
                src={item.imgSrc}
                alt="banner"
                draggable={false}
                style={{ borderRadius: 15, height: "150px", width: "100%" }}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default TopSlider;
