import api, { PublicAxios, OpenAxios } from './api';

class UserService {
  getNewsfeeds() {
    return api.get('/newsfeeds/');
  }

  getSliderImages() {
    return PublicAxios.get('/promotions/');
  }

  getTablist() {
    return PublicAxios.get('/products/categories/');
  }

  getProducts(category) {
    return PublicAxios.get(`/products/?category=${category}`);
  }

  getBalance() {
    return api.get('/wallet/');
  }

  getNotificationCount() {
    return api.get('/notifications/count');
  }

  getPromotions() {
    return PublicAxios.get('/promotions/');
  }

  getBanks() {
    return OpenAxios.get('/banks/');
  }

  getMerchantBanks() {
    return PublicAxios.get('/merchant-bank-accounts/');
  }

  getCustomBanks() {
    return api.get('/customer-bank-accounts/');
  }

  getCouponTypelist() {
    return api.get('/coupon-types/');
  }

  createDeposit(values) {
    return api.post('/deposits/', values);
  }

  withdraw(values) {
    return api.post('/withdrawals/', values);
  }

  startProduct(id) {
    return api.post(`/product/${id}/start/`);
  }

  stopProduct(id) {
    return api.post(`/product/${id}/stop/`);
  }

  checkProduct(id) {
    return api.get(`/product/${id}/game-account/`)
  }
}

export default new UserService();
