import React, { useContext, useState } from "react";
import { getCategoryDetail, productStart } from "../services";
import { AppContext } from "../App";
import Loader from "./Loader";
import { toast } from "react-toastify";
import Contact from "./Contact";

const categories = [
  {
    id: 1,
    name: "news",
    description: "News",
    //    path: "/images/tabimgs/news.png",
  },
  {
    id: 2,
    name: "",
    description: "All",
    //    path: "/images/tabimgs/all.png",
  },
  {
    id: 3,
    name: "casino",
    description: "Live",
    //    path: "/images/tabimgs/casino.png",
  },
  {
    id: 4,
    name: "slots",
    description: "Slots",
    //     path: "/images/tabimgs/slot.png",
  },
  {
    id: 5,
    name: "sportsbook",
    description: "Sports",
    //    path: "/images/tabimgs/sportsbook.png",
  },
  {
    id: 6,
    name: "lotto",
    description: "Lottery",
    //    path: "/images/tabimgs/lotto.png",
  },
];

function Tabs({ tabNum, setTabNum, gamelist, selectedGame, handleProduct, stopProduct }) {
  const [state, setState] = useContext(AppContext);
  const [active, setActive] = useState(1);
  return (
    <>
      <div class="content t3" id="tab-group-1" style={{ margin: "0px" }}>
        <div class="tab-controls tabs-rounded" data-highlight="bg-highlight">
          {categories.map((category, index) => (
            <a
              href="#"
              data-active={active == index ? true : false}
              className={`${active == index ? "bg-highlight" : ""}`}
              data-bs-toggle="collapse"
              data-bs-target={`#tab-${index}`}
              key={category.id}
              onClick={() => {
                setActive(index);
                setTabNum(category.name);
              }}
            >
              {category.description}
            </a>
          ))}
        </div>
        <div class="clearfix mb-3"></div>
        {tabNum === "news" ? (
          <iframe
            src="https://app-news-iframe.jomduit88.com"
            id="iframe-news"
            width="100%"
            style={{ borderRadius: 10, height: "680px" }}
          />
        ) : (
          <>
            {categories.map((category, index) => (
              <div
                data-bs-parent={`#tab-group-${index}`}
                class={`collapse ${active == index ? "show" : ""}`}
                id={`tab-${index}`}
              >
                <div class="row me-0 ms-0 mb-0 position-relative">
                  {gamelist.map((game, i) => (
                    <div
                      onClick={() => {
                        if (game.id) {
                          if (selectedGame === game.id) {
                            stopProduct(game.id);
                          } else {
                            handleProduct(game.ltype, game.id);
                          }
                        }
                      }}
                      class="col-4 ps-0 pe-0"
                    >
                      <div class="card card-style m-1">
                        <img
                          src={
                            game.image_mobile
                              ? game.image_mobile
                              : "images/pictures/cards/sport.png"
                          }
                          class="img-fluid"
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        {/* <span className='text-center mx-1'>Description</span> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="custom_space"></div>
      <div className="custom_bank">
        <p>Bank support : </p>
        <div>
          <img src={require("../images/bank.png")} alt="" className="w-100" />
        </div>
      </div>
      <Contact />
    </>
  );
}

export default Tabs;
