import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { AppContext } from "../App";
import { toast } from "react-toastify";
import { login } from "../services";
import MenuSidebar from "../components/MenuSidebar";
import authService from "../service/auth.service";

function SignIn() {
  const [state, setState] = useContext(AppContext);
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setData((data) => {
      return { ...data, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(data);
      await authService.login(data);
      navigate("/home");
    } catch (err) {
      let username_msg;
      let password_message;
      let refresh_msg;
      if(!err.response) return toast.error("Failed to login!", {theme: "dark"})

      const data = err.response.data;
      if (data.username) {
        username_msg = data.username[0];
        return toast.error(`Username: ${username_msg}`, {theme: "dark"})
      }
      if (data.refresh) {
        refresh_msg = data.refresh[0];
        return toast.error(`${refresh_msg}`, {theme: "dark"})
      }
      if (data.detail) {
        refresh_msg = data.detail[0];
        return toast.error(`${refresh_msg}`, {theme: "dark"})
      }
      if (data.password) {
        password_message = data.password[0];
        return toast.error(`Password: ${password_message}`, {theme: "dark"})
      }
    }
  };
  return (
    <div className="t3">
      <div id="page">
        <div class="page-content pb-0">
          <div data-card-height="cover-full" class="card mb-0 mx-0">
            <div className="t1">
              <Header />
            </div>
            <div class="card-center px-4">
              <div>
                <h1 class="font-23 font-700" style={{ color: "#e022f6" }}>
                  Login
                </h1>
              </div>
              <p class="input-title mb-2 font-14" style={{ color: "white" }}>
                Mobile No / Username
              </p>
              <div>
                <div
                  class={`input-style has-borders has-icon validate-field mb-2  ${
                    data.username ? "input-style-active" : ""
                  }`}
                >
                  <input
                    name="username"
                    onChange={handleChange}
                    type="name"
                    class={`form-control validate-name`}
                    id="form1"
                    placeholder="Username"
                  />
                  <i class="fa fa-times disabled invalid color-red-dark"></i>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                  <em>(required)</em>
                </div>
                <p class="input-title mb-2 font-14" style={{ color: "white" }}>
                  Password
                </p>
                <div
                  class={`input-style has-borders has-icon validate-field mb-4  ${
                    data.password ? "input-style-active" : ""
                  }`}
                >
                  <input
                    name="password"
                    onChange={handleChange}
                    type="password"
                    class={`form-control validate-password`}
                    id="form1a"
                    placeholder="Password"
                  />
                  <i class="fa fa-times disabled invalid color-red-dark"></i>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                  <em>(required)</em>
                </div>
                <a
                  href=".#"
                  data-back-button
                  class="btn btn-full btn_green mt-4"
                  onClick={handleSubmit}
                >
                  LOGIN
                </a>
                <p
                  class="input-title my-3 text-center font-14"
                  style={{ color: "#FFF" }}
                >
                  Do Not Have An Account Yet?
                </p>
                <a
                  href=".#"
                  data-back-button
                  class="btn mx-auto btn-full btn_green register_btn mt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signup");
                  }}
                >
                  REGISTER
                </a>

                <p
                  class="input-title my-3 text-center font-14"
                  style={{ color: "#FFF" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signup");
                  }}
                >
                  Forgot Password? Click Here
                </p>
              </div>
            </div>
            <div className="t1">
              <Footer />
              <MenuSidebar />
            </div>
            <div class="card-overlay bg-black"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
