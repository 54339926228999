import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAccount, getBanks } from "../services";
import { toast } from "react-toastify";
import { AppContext } from "../App";
import authService from "../service/auth.service";
import tokenService from "../service/token.service";

function SignUp3({ userId }) {
  const navigate = useNavigate();
  const [state, setState] = useContext(AppContext);
  const [banks, setBanks] = useState([]);
  const [data, setData] = useState({
    bank: "",
    account_number: "",
    account_name: "",
  });

  const getBanks = async () => {
    const banklist = await authService.getBanklist();
    setBanks(banklist.data);
    setData((data) => {
      return { ...data, bank: banklist.data[0].id };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      return toast.error("Unable to get userId, Please refresh the page!", {
        theme: "dark",
      });
    }

    try {
      await authService.activate({ userId, values: data });
      const uservalue = tokenService.getRegisterUser();
      await authService.login({
        username: uservalue.username,
        password: uservalue.password,
      });
      toast.success("User created successfully!", { theme: "dark" });
      navigate("/");
    } catch (err) {
      console.log("err => ", err);
      let bank_msg;
      let account_name_msg;
      let account_number_msg;
      if (!err.response) return toast.error("Failed to save the details!");

      const data = err.response.data;
      if (data.bank) {
        bank_msg = data.bank[0];
        return toast.error(`Bank: ${bank_msg}`, { theme: "dark" });
      }
      if (data.account_name) {
        account_name_msg = data.account_name[0];
        return toast.error(`Full Name: ${account_name_msg}`, { theme: "dark" });
      }
      if (data.account_number) {
        account_number_msg = data.account_number[0];
        return toast.error(`Account Number: ${account_number_msg}`, { theme: "dark" });
      }
      if (data.non_field_errors) {
        account_number_msg = data.non_field_errors[0];
        return toast.error(`${account_number_msg}`, { theme: "dark" });
      }
      return toast.error("Failed to save the details!", { theme: "dark" });
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const handleChange = (e) => {
    setData(data => {
        return { ...data, [e.target.name]: e.target.value };
    });
};

  const handleBankChange = (e) => {
    const RE = /[0-9\b]$/;
    if (RE.test(e.currentTarget.value) || e.currentTarget.value === '') {
        setData({ ...data, account_number: e.currentTarget.value });
    }
}

  return (
    <div className="t3">
      <div id="page">
        <div class="page-content pb-0">
          <div data-card-height="cover-full" class="card mb-0">
            <div class="card-center">
              <div class="text-center">
                <h1 class="font-40" style={{ color: "#e800ff" }}>
                  Bank Details
                </h1>
              </div>
              <div class="content px-4">
                <div
                  class={`input-style no-borders no-icon mb-4  ${
                    data.bank ? "input-style-active" : ""
                  }`}
                  style={{ backgroundColor: "transparent", color: "#fff" }}
                >
                  <label
                    for="form1a"
                    style={{ backgroundColor: "transparent", color: "#fff" }}
                    class="color-highlight"
                  >
                    Select A Value
                  </label>
                  <select
                    id="form1a-select"
                    style={{
                      backgroundColor: "transparent",
                      borderBottomWidth: "1px",
                    }}
                    value={data.bank}
                    onChange={handleChange}
                    name="bank"
                    class={`form-control validate-name color-dark-light`}
                  >
                    {banks.length > 0 && banks.map((bank, idx) => (
                      <option
                        className="text-black w-full"
                        value={bank.id}
                        key={idx}
                        // selected={idx === 0}
                      >
                        {bank.name}
                      </option>
                    ))}
                  </select>
                  <span>
                    <i class="fa fa-chevron-down"></i>
                  </span>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                  <i class="fa fa-check disabled invalid color-red-dark"></i>
                  <em
                    style={{ backgroundColor: "transparent", color: "#fff" }}
                  ></em>
                </div>
                <div
                  class={`input-style input-transparent no-borders has-icon validate-field mb-4  ${
                    data.fullName ? "input-style-active" : ""
                  }`}
                >
                  <input
                    type="name"
                    class={`form-control validate-name`}
                    id="form1a"
                    pattern="[A-Za-z ]{1,32}"
                    placeholder="Full Name"
                    name="account_name"
                    onChange={handleChange}
                  />
                  <label for="form1a" class="color-highlight ">
                    Your Name
                  </label>
                  <i class="fa fa-times disabled invalid color-red-dark"></i>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                  <em>(required)</em>
                </div>
                <div
                  class={`input-style input-transparent no-borders has-icon validate-field mb-4  ${
                    data.account ? "input-style-active" : ""
                  }`}
                >
                  <input
                    onChange={handleBankChange}
                    type="text"
                    class={`form-control validate-name`}
                    id="form1a"
                    placeholder="Bank Account Number"
                    value={data.account_number}
                  />
                  <label for="form1a" class="color-highlight">
                    Bank Account Number
                  </label>
                  <i class="fa fa-times disabled invalid color-red-dark"></i>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                  <em>(required)</em>
                </div>
                <a
                  href=".#"
                  data-back-button
                  class="btn btn-full btn-l font-600 font-15 gradient-highlight mt-4 rounded-s"
                  onClick={handleSubmit}
                >
                  Save And Continue
                </a>
              </div>
            </div>
            <div class="card-overlay bg-black"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp3;
