import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../App'
import { signup } from '../services'
import { toast } from 'react-toastify'
import Header from '../components/Header'
import authService from '../service/auth.service'
import tokenService from '../service/token.service'

function SignUp({setUserId}) {
    const navigate = useNavigate()
    const [state, setState] = useContext(AppContext)
    const [data, setData] = useState({
        username: "",
        password: "",
        password2: "",
        cc: '60',
        mobile: "",
    })



    const handleSubmit = async () => {
        try {
            const response = await authService.register(data);
            console.log(response)
            if(response.status === 201) {
                if (response.data.id) {
                    setUserId(response.data.id);
                    tokenService.setRegisterUser(data);
                    navigate('/signup2');
                }
            }
        }catch(err) {
            let username_msg;
            let cc_message;
            let mobile_message;
            let password_message;
            let password2_message;
            let non_field_errors_message;
            if(err.response) {
                const data = err.response.data;
                if (data.username) {
                    username_msg = data.username[0];
                    return toast.error(`Username: ${username_msg}`, {theme: 'dark'})
                }
                if (data.cc) {
                    cc_message = data.cc[0];
                    return toast.error(`CC: ${cc_message}`, {theme: 'dark'})
                }
                if (data.mobile) {
                    mobile_message = data.mobile[0];
                    return toast.error(`Phone Number: ${mobile_message}`, {theme: 'dark'})
                }
                if (data.password) {
                    password_message = data.password[0];
                    return toast.error(`Password: ${password_message}`, {theme: 'dark'})
                }
                if (data.password2) {
                    password2_message = data.password2[0];
                    return toast.error(`Confirm Password: ${password2_message}`, {theme: 'dark'})
                }
                if (data.non_field_errors) {
                    non_field_errors_message = data.non_field_errors[0];
                    return toast.error(non_field_errors_message, {theme: 'dark'})
                }
            }
        }
    }

    return (
        <div className="t3" >
            <div id="page">
                <div class="page-content pb-0">
                    <div data-card-height="cover-full" class="card mb-0 mx-0">
                        <div className="t1">
                            <Header />
                        </div>
                        <div class="card-center">
                            {/* <div class="text-left" style={{paddingLeft:"15px"}}>
                                <h1 class="font-40 color-white">Sign Up</h1>
                            </div> */}
                            <div class="content">
                                <p class="input-title my-1 font-14" style={{ color: "#FFF" }}>Username :</p>
                                <div class={`input-style has-borders no-icon validate-field mb-4  ${data.user ? "input-style-active" : ""}`}>
                                    <input value={data.username} onChange={e => setData({ ...data, username: e.target.value })} type="name" class={`form-control validate-name`} id="form1a" placeholder="Username" />
                                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                                    <i class="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div>

                                <p class="input-title my-1 font-14" style={{ color: "#FFF" }}>Mobile No :</p>
                                <div class={`input-style has-borders no-icon validate-field mb-4  ${data.phone ? "input-style-active" : ""}`}>
                                    <input value={data.mobile} onChange={e => setData({ ...data, mobile: e.target.value })} type="number" class={`form-control validate-name`} id="form1a" placeholder="Phone number" />
                                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                                    <i class="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div>

                                <p class="input-title my-1 font-14" style={{ color: "#FFF" }}>Password :</p>
                                <div class="input-style has-borders no-icon validate-field mb-4">
                                    <input value={data.password} onChange={e => setData({...data, password: e.target.value})} type="password" class="form-control validate-text" id="form3" placeholder="Password" />
                                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                                    <i class="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div>
                                {/* <div class={`input-style has-borders no-icon validate-field mb-4  ${data.password ? "input-style-active" : ""}`}>
                                    <i class="ps-1 fa fa-lock"></i>
                                    <input onChange={e => setData({ ...data, password: e.target.value })} type="password" class={`form-control validate-password`} id="form1a" placeholder="Password" />
                                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                                    <i class="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div> */}
                                <p class="input-title my-1 font-14" style={{ color: "#FFF" }}>Confirm Password :</p>

                                <div class={`input-style has-borders no-icon validate-field mb-4  ${data.password2 ? "input-style-active" : ""}`}>
                                    <input value={data.password2} onChange={e => setData({ ...data, password2: e.target.value })} type="password" class={`form-control validate-password`} id="form1a" placeholder="Confirm Password" />
                                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                                    <i class="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div>

                                <a href=".#" data-back-button class="btn mx-auto w-100 btn-full btn_green register_btn mt-4" onClick={e => {
                                    e.preventDefault()
                                    handleSubmit()
                                }}>REGISTER</a>
                               
                                <p class="input-title text-center my-1 font-14" style={{ color: "#FFF" }}>Already Have An Account?</p>
                                <p class="input-title text-center my-1 font-14" style={{ color: "#FFF" }} onClick={e => {
                                    e.preventDefault()
                                    navigate("/")
                                }}>Login Here</p>
                            </div>
                        </div>
                        {/* <div className="t1">
                            <Footer />
                        </div> */}
                        <div class="card-overlay bg-black"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp