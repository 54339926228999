import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function MenuSidebar() {
    const navigate = useNavigate()
    return (
        <div id="menu-sidebar" data-menu-active="nav-welcome"
            class="offcanvas offcanvas-start offcanvas-detached d-block">
            <div class="menu-size" style={{ width: "270px" }}>
                <div>
                    <div class="page-title custom_title sidebar-title d-flex">
                        <div class="align-self-center">
                           <img src={require("../images/logo.png")} alt="" width="90px" />
                        </div>
                    </div>

                    {/* <div class="divider divider-margins mb-3 opacity-50"></div> */}

                    <div class="list-group list-custom list-menu-large">
                        <a href="#" id="nav-welcome" class="list-group-item list-group-item-active" onClick={e => {
                            e.preventDefault()
                            navigate("/home")
                        }}> 
                            <img src={require("../images/nav/icon_home.png")} alt="" width="33px" height="auto" />
                            <div>Home</div>
                            <i class="bi bi-chevron-right"></i>
                        </a>
                        <a href="#" id="nav-pages" class="list-group-item" onClick={e => {
                            e.preventDefault()
                            navigate("/withdraw")
                        }}>
                            <img src={require("../images/nav/icon_lottery.png")} alt="" width="33px" height="auto" />
                            <div>Withdraw</div>
                            <i class="bi bi-chevron-right"></i>
                        </a>
                        <a href="#" id="nav-pages" class="list-group-item" onClick={e => {
                            e.preventDefault()
                            navigate("/deposit")
                        }}>
                            <img src={require("../images/nav/icon_lottery.png")} alt="" width="33px" height="auto" />
                            <div>Deposit</div>
                            <i class="bi bi-chevron-right"></i>
                        </a>
                        <a href="#" id="nav-homes" class="list-group-item" onClick={e => {
                            e.preventDefault()
                            navigate("/promotions")
                        }}>
                            <img src={require("../images/nav/icon_promotion.png")} alt="" width="33px" height="auto" />
                            <div>Promotions</div>
                            <i class="bi bi-chevron-right"></i>
                        </a>
                        <a href="#" id="nav-comps" class="list-group-item" onClick={e => {
                            e.preventDefault()
                            navigate("/history")
                        }}>
                            <img src={require("../images/nav/icon_poker.png")} alt="" width="33px" height="auto" />
                            <div>Transaction History</div>
                            <i class="bi bi-chevron-right"></i>
                        </a>
                        <a data-bs-toggle="offcanvas" data-bs-target="#menu-highlights" href="#" class="list-group-item" onClick={e => {
                            e.preventDefault()
                            navigate("/account")
                        }}>
                            <img src={require("../images/nav/icon_number.png")} alt="" width="33px" height="auto" />
                            <div>Account</div>
                            <i class="bi bi-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MenuSidebar