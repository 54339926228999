class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      return user.refresh
    }
    return null;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      return user.access
    }
    return null;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.access = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    console.log(JSON.stringify(user));
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  setRegisterUser(user) {
    console.log("setregisterUser => ", JSON.stringify(user));
    localStorage.setItem("registerUser", JSON.stringify(user));
  }

  getRegisterUser() {
    return JSON.parse(localStorage.getItem("registerUser"));
  }

  removeRegisterUser(user) {
    console.log("removeregisterUser => ", JSON.stringify(user));
    localStorage.removeItem("registerUser");
  }
}

export default new TokenService();
